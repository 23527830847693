// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const images = require.context('images', true)
const imagePath = (name) => images(name, true)

// Stylesheets
import "stylesheets/application.scss"
import "vue-select/dist/vue-select.css"
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/list/main.css';
import 'stylesheets/fonts/_feather';
import 'stylesheets/fonts/fontawesome/fontawesome';
import 'stylesheets/fonts/fontawesome/regular';
import 'stylesheets/fonts/fontawesome/solid';


import RailsUJS from  "@rails/ujs"
RailsUJS.start()
import "bootstrap"
import "../components"

import axios from 'axios'

import * as Trix from "trix"
import "../trix-editor-overrides"

Trix.config.lang = {
  attachFiles: "Bestand toevoegen",
  bold: "Vet",
  bullets: "Lijst",
  byte:  "Byte",
  bytes: "Bytes",
  captionPlaceholder: "Onderschrift toevoegen…",
  code: "Code",
  heading1: "Hoofding",
  indent: "Inspringen",
  italic: "Cursief",
  link: "Link",
  numbers: "Opsomming",
  outdent: "Insprong verkleinen",
  quote: "Quote",
  redo: "Opnieuw",
  remove: "Verwijderen",
  strike: "Doorstrepen",
  undo: "Ongedaan maken",
  unlink: "Link weghalen",
  url: "URL",
  urlPlaceholder: "Een URL ingeven…",
  GB: "GB",
  KB: "KB",
  MB: "MB",
  PB: "PB",
  TB: "TB",
}

require("@rails/actiontext")

function setupCSRFToken() {
  const csrfTokenElement = document.querySelector("meta[name=csrf-token]")
  let csrfToken = ''

  if (csrfTokenElement) {
    csrfToken = csrfTokenElement.content
  }
  if (csrfToken) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }
}

window.addEventListener('DOMContentLoaded', setupCSRFToken)
